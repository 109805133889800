import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { RHFSelect } from '#/components/shared/hook-form';
import useLocales from '#/hooks/useLocales';
import {
  AccessRightOptions,
  SERVICE_OPTIONS,
  TAX_FOLDER_OPTIONS,
} from '#/components/pages/User/successors/schema-validation';
import Iconify from '#/components/shared/ui/Iconify';
import {
  invalidateSuccessorQueries,
  useGetInvitationsList,
  useUpdateInvitation,
} from '#/api/successor';

type Props = {
  nestIndex: number;
  closeModal: () => void;
};

const SpecificServiceAccessFieldArray = ({ nestIndex, closeModal }: Props) => {
  const { control, watch } = useFormContext();

  const { translate } = useLocales();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `invitations.${nestIndex}.specific_service_access`,
  });
  const { data: list } = useGetInvitationsList('SUCCESSOR');

  const successorId = list?.find(
    (item) => item.first_name === watch(`invitations.${nestIndex}.first_name`)
  )?.unique_id;

  const { mutateAsync: updateInvitationRights } =
    useUpdateInvitation(successorId);

  const handleUpdateInvitationRights = async () => {
    try {
      await updateInvitationRights({
        ...watch(`invitations[${nestIndex}]`),
      });
      invalidateSuccessorQueries.getInvitationsList();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Stack spacing={2}>
      {fields.map((field, accessIndex) => (
        <Stack spacing={2} key={field.id} direction="row" alignItems="center">
          <Stack spacing={2} sx={{ width: 1 }}>
            <RHFSelect
              name={`invitations[${nestIndex}].specific_service_access[${accessIndex}].service`}
              label={String(translate('global.formLabels.artifact'))}
            >
              {SERVICE_OPTIONS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography sx={{ textTransform: 'none' }}>
                    {String(translate(option.label))}
                  </Typography>
                </MenuItem>
              ))}
            </RHFSelect>

            <RHFSelect
              name={`invitations[${nestIndex}].specific_service_access[${accessIndex}].accessLevel`}
              label={String(translate('global.accessLevel'))}
            >
              {AccessRightOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Typography sx={{ textTransform: 'none' }}>
                    {String(translate(option.label))}
                  </Typography>
                </MenuItem>
              ))}
            </RHFSelect>
            {watch(
              `invitations[${nestIndex}].specific_service_access[${accessIndex}].service`
            ) === 'tax' && (
              <RHFSelect
                name={`invitations[${nestIndex}].specific_service_access[${accessIndex}].action`}
                label={String(translate('global.formLabels.action'))}
              >
                {TAX_FOLDER_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Typography sx={{ textTransform: 'none' }}>
                      {String(translate(option.label))}
                    </Typography>
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
          </Stack>
          <Button
            type="button"
            size="small"
            color="error"
            onClick={() => remove(accessIndex)}
          >
            <Iconify
              icon="material-symbols:delete-outline"
              width={20}
              height={20}
            />
          </Button>
        </Stack>
      ))}

      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          type="button"
          onClick={() => append({ service: '', accessLevel: '' })}
        >
          <Typography>
            {String(translate('global.addServiceAccess'))}
          </Typography>
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            if (successorId) {
              handleUpdateInvitationRights();
              closeModal();
            } else {
              closeModal();
            }
            // closeModal();
          }}
        >
          <Typography>{String(translate('global.done'))}</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};

export default SpecificServiceAccessFieldArray;
